.colorful-button {
  color: white;
  background: linear-gradient(to right, #fdbb2d 0%, #22c1c3 100%);
  border-color: aliceblue;
  border-radius: 10px;
}
.modal {
  position: fixed;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 0.5px solid grey;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  /* background-color: rgba(0, 0, 0, 0.5); */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient {
  background: linear-gradient(270deg, #67b26f, #ffdd00, #67b26f);
  background-size: 600% 600%;
  color: "#ffffff";
  animation: gradientAnimation 7s ease infinite;
  border-color: "transparent";
  /* width: 100vw;
  height: 100vh; */
}

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(-360deg);
  }
}

.animated-gradient-text {
  color: #f35626;
  background-image: linear-gradient(92deg, #f35626, #feab3a);
  background-clip: text;
  -webkit-background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-text-fill-color: transparent;
  animation: hue 10s infinite linear;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

.glow-component {
  -webkit-box-shadow: 0px 0px 171px 28px rgba(255, 175, 46, 0.2);
  -moz-box-shadow: 0px 0px 171px 28px rgba(255, 175, 46, 0.2);
  box-shadow: 0px 0px 171px 28px rgba(255, 175, 46, 0.2);
  background-color: transparent;
  display: inline-block;
  /* padding: 10px; Optional: Add padding if needed */
  text-align: center;
  text-justify: center;
  border-radius: 30px;
  align-items: center;
}
